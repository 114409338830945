var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ant-pro-pages-account-projects-cardList" },
    [
      _c("a-list", {
        attrs: {
          loading: _vm.loading,
          "data-source": _vm.data,
          grid: { gutter: 24, xxl: 3, xl: 2, lg: 2, md: 2, sm: 2, xs: 1 },
        },
        scopedSlots: _vm._u([
          {
            key: "renderItem",
            fn: function (item) {
              return _c("a-list-item", {}, [
                _c(
                  "a",
                  { attrs: { href: item.url, target: "_blank" } },
                  [
                    _c(
                      "a-card",
                      {
                        staticClass: "ant-pro-pages-account-projects-card",
                        attrs: { hoverable: "" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            slot: "cover",
                            src: item.cover,
                            alt: item.title,
                          },
                          slot: "cover",
                        }),
                        _c(
                          "a-card-meta",
                          { attrs: { title: item.title } },
                          [
                            _c(
                              "template",
                              { slot: "description" },
                              [
                                _c("ellipsis", { attrs: { length: 50 } }, [
                                  _vm._v(_vm._s(item.description)),
                                ]),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _c("div", { staticClass: "cardItemContent" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm._f("fromNow")(item.updatedAt))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ])
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }